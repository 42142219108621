import '../css/reset.css'
import 'swiper/swiper-bundle.css';
import '../css/nav.scss'
import '../css/index.scss'
import '../css/mouse.scss'

import QRCode from 'qrcodejs2'
import Swiper from 'swiper/bundle';
import $ from 'jquery'

function generateQrcode() { // pc端动态二维码
    let channel = getQueryVariable('channel') || getQueryVariable('u');
    const url = 'https://sz.petrichorlife.xyz/?u='+(channel || 'P59665973') // pc端标识
    new QRCode(document.getElementById("qrcode1"), {text: url, width: 64, height: 64});
    new QRCode(document.getElementById("qrcode2"), {text: url, width: 142, height: 142});
}
$(document).ready(function () {
    // generateQrcode()
})


function getQueryVariable(name) {
    const url = decodeURI(location.search); // 获取url中"?"符后的字串(包括问号)
    let query = {};
    if (url.indexOf("?") != -1) {
        const str = url.substr(1);
        const pairs = str.split("&");
        for (let i = 0; i < pairs.length; i++) {
            const pair = pairs[i].split("=");
            if (pair[0] === name) return pair[1]; // 返回 参数值
        }
    }
    return false
}


const platform = judgeClient()

const swiper = new Swiper('.swiper-container', {
    direction: 'vertical',
    slidesPerView: 1,
    spaceBetween: 30,
    mousewheel: true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    on: {
        slideChange: function () {
            if (this.activeIndex === 4 && platform !== 'PC') {
                $('.nav').hide()
            }
            if (this.activeIndex === 3 && platform !== 'PC') {
                $('.nav').show()
            }
        },
    }
})

// 备案号处理
if (getQueryVariable('ref') === 'b3b1fdc2aff6f7dfc3c9ca95234f17ec') {
    $('.beianhao')[0].innerText = '赣ICP备18013973号-1'
    $('.beianhao-compony')[0].innerText = '上饶市米汇信息科技有限公司 '
    $('.beianhao-compony')[1].innerText = '上饶市米汇信息科技有限公司 '
}

$.ajax({
    url: "https://api-9f9d25.sz365.cn/api/front/link_list",	//请求url
    type: "GET",	//请求类型  post|get
    // data : "key=value&key1=value2",	//后台用 request.getParameter("key");
    dataType: "json",  //返回数据的 类型 text|json|html--
    success: function (res) {	//回调函数 和 后台返回的 数据
        //	var users = JSON.parse(data);
        // var tBody = $("#tbBody");
        // for(var i=0; i<users.length; i++){  //通过遍历，创建行数据
        //     var tr = $("<tr><td>"+users[i].id+"</td><td>"+users[i].name+"</td><td>"+users[i].school+"</td></tr>")
        //     tBody.append(tr);
        // }
        var ul = $('#friend-link')
        if (Array.isArray(res.data)) {
            res.data.forEach(item => {
                ul.append(`<li><a href="${item.url}" rel="nofollow" target="_blank">${item.name}</a></li>`)
            })
        }
        ul.append('<i></i>')
        ul.append('<i></i>')
        ul.append('<i></i>')
        ul.append('<i></i>')
    }
});


$('.btn-start-wrapper').click(function () {
    _hmt.push(['_trackEvent', '按钮', '下载']);
    let channel = getQueryVariable('channel') || getQueryVariable('u');
    console.log('platform', platform);
    if (platform === 'IOS') {
        jumpToQQGroup()
        // window.location.href = 'https://share.windfallhome.top/welcome.html?chn_ref=' + (channel || 'P65239288')
    }else{ // not ios
        window.location.href = 'https://api-9f9d25.sz365.cn/api/apk/download?channel=' + (channel || 'P65239288')
    }
})

// 挣钱攻略
$('.earn-money').click(function () {
    $('.videobox').show()
    $('.earn-video')[0].play()
})
$('.ico-play').click(function () {
    $('.videobox').show()
    $('.earn-video')[0].play()
})
$('.close_off').click(function () {
    $('.videobox').hide()
    $('.earn-video')[0].pause()
})

$('.nav-right-img').click(function (e) {
    e.stopPropagation()
    const tabs = $('.right-tabs')
    if (tabs.height() === 0) {
        tabs.css({ height: '1rem', width: '1rem' })
    } else {
        tabs.css({ height: 0, width: 0 })
    }
})

/*判断客户端*/
function judgeClient() {
    let u = navigator.userAgent;
    let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;   //判断是否是 android终端
    let isIOS = !!u.match(/[^;]+;( U;)?.+Mac OS X/);     //判断是否是 iOS终端 include mac
    console.log('是否是Android：' + isAndroid); //true,false
    console.log('是否是iOS：' + isIOS);
    if (isAndroid) {
        return 'Android';
    } else if (isIOS) {
        return 'IOS';
    }
}

function isdingding() {
    // 判断是不是钉钉
    var ua = navigator.userAgent.toLowerCase();
    return ua.indexOf("dingtalk") >= 0;
}

function jumpToBusinessQQ () {
    if (platform === 'Android') {
        window.location.href = 'mqqwpa://im/chat?chat_type=wpa&uin=1604416373'
    } else {
        window.location.href = 'mqq://im/chat?chat_type=wpa&uin=1604416373&version=1&src_type=web'
    }
}

function jumpToQQGroup () {
    window.location.href = 'mqqapi://card/show_pslcard?src_type=internal&version=1&uin=315086240&authSig=golteHBe1+MKFX0etJM/vfV8HmAUxKyBHc4W8vXnhmndLreen9mVb32dpkg4iKrl&card_type=group&source=external&jump_from=webapi'
}

$('#qq_link').click(function () {
    jumpToBusinessQQ()
})

$('#qq_group_link').click(function () {
    jumpToQQGroup()
})

if (isdingding()) {
    dd.biz.navigation.setRight({
        show: true, //控制按钮显示， true 显示， false 隐藏， 默认true
        control: true, //是否控制点击事件，true 控制，false 不控制， 默认false
        showIcon: true, //是否显示icon，true 显示， false 不显示，默认true； 注：具体UI以客户端为准
        onSuccess: () => {
            //如果control为true，则onSuccess将在发生按钮点击事件被回调
            dd.biz.util.share({
                type: 0, // 分享类型，0:全部组件 默认； 1:只能分享到钉钉；2:不能分享，只有刷新按钮
                url: window.location.href,
                content: '轻松趣玩、快速挣钱的手机赚钱平台',
                title: '闪挣官网',
                image: '',
                onSuccess: () => { },
                onFail: () => { }
            });
        },
        onFail: () => { },
    });
}
